<template>
	<v-sheet flat>
		<template v-if="!loggedIn">
			<please-login></please-login>
		</template>
		<template v-else>
			<v-card v-if="!isVideo" color="lightest" class="rounded-xl" flat>
                Something has gone wrong here
            </v-card>
			<v-sheet v-else>
				<video-room v-if="start" :id="appointment.video_room"></video-room>
				<template v-else>
					<v-card
						class="mx-auto"
						flat
						width="500"
					>
						<v-card-text>
							<template v-if="isInternal">
								<p v-if="videoIsStarted">
									You're about to enter a video meeting. Please note that the meeting will be recorded. If you have any  concerns or questions, please get in touch with us using the live chat (bottom left).
								</p>
								<p v-else>Please wait for your session to start</p>
							</template>
							<p v-else>
								You are about to open an external link.
							</p>
						</v-card-text>
						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn :disabled="disableClick" @click="goToVideo" color="primary">
								<v-icon v-if="icon" small class="ml-2  mr-2">{{ icon }}</v-icon>
								Start Video
							</v-btn>
						</v-card-actions>
					</v-card>

				</template>
			</v-sheet>
          
		</template>
	</v-sheet>
</template>

<script>
import VideoRoom from "@/views/Video"
import PleaseLogin from "@c/ui/PleaseLogin"
export default {
	name: "JoinAVideo",
	props: {
		id: { type: String },
	},
	data: () => {
		return	 {
			start: null
		}
	},
	computed: {
		auth() {
			return this.$store.state.auth.data;
		},
		loggedIn() {
			return this.auth.id || false;
		},
		appointmentParticipant() {
			return this.$store.state.appointmentParticipant.data;
		},
		appointment() {
			return this.$store.state.appointment.data;
		},
		isVideo(){
			if( this.appointment && this.appointment.methodology == "video" ){
				return true;
			}
			return false;
		},
		videoIsStarted(){
			return this.appointment.video_room ? true : false;
		},
		isInternal(){
			return this.appointment.video_medium == 'internal'
		},
		disableClick(){
			if( !this.isInternal ){
				return false;
			}
			else{
				return !this.videoIsStarted;
			}
		},
		icon() {
			if (this.appointment.video_medium == "teams") {
				return "mdi-microsoft";
			}
			if (this.appointment.video_medium == "google-meet") {
				return "mdi-google";
			}
			return false;
		},
	},
	components: {
		PleaseLogin, 
		VideoRoom
	},
	methods: {
		goToVideo() {
            if( this.appointment.video_medium == "internal" ){
                this.start = true;
            }
            else{
                window.location = this.appointment.participation_link;
            }
		},
	},
	created() {
		window.scrollTo(0, 0);
		this.$store.dispatch("appointmentParticipant/openDBChannel", {id: this.id});
	},
};
</script>
